import React from 'react';
import Box from '@mui/material/Box';
import qs from 'qs';
import { useLocation } from 'react-router';
import { PageContainer } from '../../components';
import Signin from '../../components/Signin';

const SigninPage = () => {
  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });
  const refer = search.refer
    ? `/${decodeURIComponent(search.refer as string)}`
    : '/';

  return (
    <PageContainer title="Login" fullWidth>
      <Box sx={{ marginBottom: '2rem' }}>
        <Signin showInCard={false} redirectTo={refer} />
      </Box>
    </PageContainer>
  );
};

export default SigninPage;
